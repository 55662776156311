import {
    SuperNaviManager,
} from '../utils/superNavi';
import {
    simpleText,
    templateDesktopLvl1,
    templateDesktopLvl2,
    templateDesktopLvl3,
    templateHighlights,
} from '../templates/superNavi';

/**
 * SuperNaviDesktop
 *
 * Responsible for handling the desktop version of the navigation.
 */
export default class SuperNaviDesktop extends SuperNaviManager {
    constructor() {
        super('desktop');

        /**
         * UI elements.
         */
        this.headerHeight = null;
        this.header = document.getElementsByClassName('header__wrapper')[0];
        this.flyoutEl = document.querySelector('.super-navi-desktop .flyout');
        this.closeBtns = document.querySelectorAll('.super-navi-desktop .close-btn');

        /**
         * Placeholders (places where to render different menu template fragments).
         */
        this.lvl1Placeholder = document.querySelector('[data-super-navi-desktop-lvl1-placeholder]');
        this.lvl2Placeholder = document.querySelector('[data-super-navi-desktop-lvl2-placeholder]');
        this.lvl3Placeholder = document.querySelector('[data-super-navi-desktop-lvl3-placeholder]');
        this.titlePlaceholder = document.querySelector('[data-super-navi-desktop-title-placeholder]');
        this.highlightsPlaceholder = document.querySelector('[data-super-navi-desktop-highlights-placeholder]');
        this.highlightsTitlePlaceholder = document.querySelector('[data-super-navi-desktop-highlights-title-placeholder]');

        /**
         * Menu state.
         */
        this.activeLvl1Path = null;
        this.isMenuOpen = false;

        /**
         * Initial render of LVL1 menu.
         */
        this.renderMenus([[this.lvl1Placeholder, templateDesktopLvl1, this.superNaviData]]);

        this.init();
    }

    init() {
        /**
         * Register general UI event listeners.
         */
        Array.prototype.forEach.call(
            this.closeBtns,
            (el) => {
                el.addEventListener(
                    'click',
                    (event) => {
                        event.preventDefault();
                        this.closeMenu();
                        this.hideOverlay();
                    },
                );
            },
        );
    }

    /**
     * Handle header background color.
     */
    handleHeaderBackgroundColor(activate) {
        if (activate) {
            this.header.classList.add('header__wrapper--no-transparency');
        } else {
            this.header.classList.remove('header__wrapper--no-transparency');
        }
    }

    /**
     * Open and initialize menu.
     */
    openMenu() {
        if (!this.headerHeight) {
            this.headerHeight = document.querySelector('.header__wrapper').offsetHeight;
            this.flyoutEl.style.top = `${this.headerHeight}px`;
        }

        this.flyoutEl.classList.add('active');
        this.isMenuOpen = true;
        const overlayEl = this.showOverlay();
        const boundCloseMenu = this.closeMenu.bind(this);
        overlayEl.addEventListener(
            'click',
            boundCloseMenu,
        );

        this.handleHeaderBackgroundColor(true);
    }

    /**
     * Close menu.
     */
    closeMenu() {
        this.flyoutEl.classList.remove('active');
        this.renderMenus([
            [this.lvl1Placeholder, templateDesktopLvl1, this.superNaviData],
        ]);
        this.isMenuOpen = false;

        this.handleHeaderBackgroundColor(false);
    }

    /**
     * Handle the requested path. A path represents the target menu to render/go-to, and can take
     * the following forms:
     * - [1] -> 1st menu of depth 0.
     * - [3] -> 3rd menu of depth 0.
     * - [3, 2] -> 2nd menu of depth 1, the deepest desktop menu level.
     *
     * @param {Array} path - An array representing the menu requested by the user.
     */
    proceedPath(path) {
        /**
         * Close the menu if clicking again on the active LVL1 item.
         */
        if (
            this.isMenuOpen
            && path.length === 1
            && this.activeLvl1Path === path[0]
        ) {
            this.closeMenu();
            this.hideOverlay();
            return;
        }

        // eslint-disable-next-line prefer-destructuring
        this.activeLvl1Path = path[0];

        /**
         * Handle LVL1 menu item.
         */
        if (path.length === 1) {
            this.openMenu();
            this.renderMenus([
                [
                    this.titlePlaceholder,
                    simpleText,
                    this.superNaviData.data[path[0]].label,
                ],
                [
                    this.highlightsTitlePlaceholder,
                    simpleText,
                    this.superNaviData.data[path[0]].highlights.title,
                ],
                [
                    this.highlightsPlaceholder,
                    templateHighlights,
                    this.superNaviData.data[path[0]].highlights.pages,
                ],
                [
                    this.lvl1Placeholder,
                    templateDesktopLvl1,
                    {
                        ...this.superNaviData,
                        activeIndex: path[0],
                    },
                ],
                [
                    this.lvl2Placeholder,
                    templateDesktopLvl2,
                    this.superNaviData.data[path[0]],
                ],
                [
                    this.lvl3Placeholder,
                    '',
                    '',
                ],
            ]);
        }

        /**
         * Handle LVL2 menu item.
         */
        if (path.length === 2) {
            this.renderMenus([
                [
                    this.lvl2Placeholder,
                    templateDesktopLvl2,
                    {
                        ...this.superNaviData.data[path[0]],
                        activeIndex: path[1],
                    },
                ],
                [
                    this.lvl3Placeholder,
                    templateDesktopLvl3,
                    this.superNaviData.data[path[0]].data[path[1]].data,
                ],
            ]);
        }
    }
}
