import { scrollIntoView } from 'scroll-js';
import {
    SuperNaviManager,
} from '../utils/superNavi';
import {
    templateMobileLvl1,
    templateMobileLvl2,
    templateMobileLvl3,
} from '../templates/superNavi';

/**
 * SuperNaviMobile
 *
 * Responsible for handling the mobile version of the navigation.
 */
export default class SuperNaviMobile extends SuperNaviManager {
    constructor() {
        super('mobile');

        /**
         * UI elements.
         */
        this.scrollTopTarget = document.querySelector('[data-super-navi-scroll-top-target]');
        this.flyoutEl = document.querySelector('.super-navi-mobile .flyout');
        this.toggleBtnEl = document.querySelector('.toggle-btn');
        this.toggleBtnIconEl = document.querySelector('.toggle-icon');
        this.sliderEl = document.querySelector('[data-super-navi-mobile-slider]');

        /**
         * Placeholders (places where to render different menu template fragments).
         */
        this.lvl1Placeholder = document.querySelector('[data-super-navi-mobile-lvl1-placeholder]');
        this.lvl2Placeholder = document.querySelector('[data-super-navi-mobile-lvl2-placeholder]');
        this.lvl3Placeholder = document.querySelector('[data-super-navi-mobile-lvl3-placeholder]');

        /**
         * Menu state.
         */
        this.isMenuOpen = false;

        this.init();
    }

    init() {
        /**
         * Register general UI event listeners.
         */
        this.toggleBtnEl.addEventListener(
            'click',
            (event) => {
                event.preventDefault();
                this.toggleMenu();
            },
        );
    }

    /**
     * Update menu slider position offset.
     *
     * @param {number} position - A number from 0 to 2, representing the position to set the menu
     * to, 0 being the root, and 2 the deepest.
     * @param {boolean} disableAnimation - Disable the CSS animation, useful when we want to
     * instantly position the menu.
     */
    slideToMenu(position, disableAnimation) {
        if (disableAnimation) {
            /**
             * Has priority over CSS, because in-lined.
             */
            this.sliderEl.style.transitionDuration = '0s';

            /**
             * Even if the time is set to '0s', an animation is still going on, so we need to wait
             * for it to finish before to restore the transitionDuration.
             */
            this.overlayEl.onanimationend = () => {
                this.sliderEl.style.transitionDuration = null;
            };
        }

        this.sliderEl.style.transform = `translateX(-${(100 / 3) * position}%)`;

        scrollIntoView(
            this.scrollTopTarget,
            this.flyoutEl,
            {
                behavior: 'smooth',
            },
        );
    }

    /**
     * Handle the requested path. A path represents the target menu to render/go-to, and can take
     * the following forms:
     * - [0] -> Menu root, depth 0.
     * - [0, 1] -> 1st menu of depth 1.
     * - [0, 3] -> 3rd menu of depth 1.
     * - [0, 3, 2] -> 2nd menu of depth 2, the deepest mobile menu level.
     *
     * Important note: the mobile, unlike the desktop menu, has contains its own root in the path,
     * because no menu is shown on mobile until toggled by the user.
     *
     * @param {Array} path - An array representing the menu requested by the user.
     */
    proceedPath(path) {
        /**
         * Handle LVL1 menu item.
         */
        if (path.length === 1) {
            this.renderMenus([
                [
                    this.lvl1Placeholder,
                    templateMobileLvl1,
                    this.superNaviData,
                ],
            ]);
        }

        /**
         * Handle LVL2 menu item.
         */
        if (path.length === 2) {
            this.renderMenus([
                [
                    this.lvl2Placeholder,
                    templateMobileLvl2,
                    this.superNaviData.data[path[1]],
                ],
            ]);
        }

        /**
         * Handle LVL3 menu item.
         */
        if (path.length === 3) {
            this.renderMenus([
                [
                    this.lvl3Placeholder,
                    templateMobileLvl3,
                    {
                        ...this.superNaviData.data[path[1]].data[path[2]],
                        parentLabel: this.superNaviData.data[path[1]].label,
                    },

                ],
            ]);
        }

        this.slideToMenu(path.length - 1, false);
    }

    /**
     * Open and initialize menu.
     */
    openMenu() {
        this.renderMenus([
            [
                this.lvl1Placeholder,
                templateMobileLvl1,
                this.superNaviData,
            ],
        ]);
        this.flyoutEl.classList.add('touched');
        this.flyoutEl.classList.add('active');
        this.toggleBtnIconEl.classList.add('active');
        this.slideToMenu(0, true);
        this.isMenuOpen = true;
    }

    /**
     * Close menu.
     */
    closeMenu() {
        this.flyoutEl.classList.remove('active');
        this.toggleBtnIconEl.classList.remove('active');
        this.isMenuOpen = false;
    }

    /**
     * Toggle menu.
     */
    toggleMenu() {
        if (this.isMenuOpen) {
            this.hideOverlay();
            this.closeMenu();
        } else {
            const overlayEl = this.showOverlay();
            this.openMenu();
            const boundCloseMenu = this.closeMenu.bind(this);
            overlayEl.addEventListener(
                'click',
                boundCloseMenu,
            );
        }
    }
}
