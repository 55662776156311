import { isIe11 } from '../support/detectBrowser';

/**
 * SuperNaviMobile
 *
 * Responsible for handling the mobile version of the navigation.
 */
export default class SuperNaviJobsMobile {
    constructor() {
        /**
         * UI elements.
         */
        this.flyoutEl = document.querySelector('.super-navi-mobile .flyout');
        this.toggleBtnEl = document.querySelector('.toggle-btn');
        this.toggleBtnIconEl = document.querySelector('.toggle-icon');
        this.sliderEl = document.querySelector('[data-super-navi-mobile-slider]');

        /**
         * Menu state.
         */
        this.isMenuOpen = false;

        /**
         * Overlay instance storage, used across showOverlay() and hideOverlay() functions.
         */
        this.overlayEl = null;

        /**
         * Over state, used to avoid having multiple ones.
         */
        this.isOverlayActive = false;

        this.init();
    }

    init() {
        /**
         * Register general UI event listeners.
         */
        this.toggleBtnEl.addEventListener(
            'click',
            (event) => {
                event.preventDefault();
                this.toggleMenu();
            },
        );
    }

    /**
     * Show overlay (the darker background over the whole page when the menu is open).
     */
    showOverlay() {
        if (this.isOverlayActive) {
            return this.overlayEl;
        }

        this.overlayEl = document.createElement('div');
        this.overlayEl.classList.add('overlay', `overlay--${this.device}`);

        const boundhideOverlay = this.hideOverlay.bind(this);
        this.overlayEl.addEventListener(
            'click',
            boundhideOverlay,
        );

        document.getElementsByTagName('body')[0].appendChild(this.overlayEl);

        this.isOverlayActive = true;

        return this.overlayEl;
    }

    /**
     * Hide overlay.
     */
    hideOverlay() {
        /**
         * Internet Explorer 11 doesn't support "onanimationend".
         */
        if (isIe11()) {
            // Equivalent to this.overlayEl.remove(), compatible with IE11.
            this.overlayEl.outerHTML = '';
            return;
        }

        this.overlayEl.onanimationend = () => {
            this.overlayEl.remove();
        };

        this.overlayEl.classList.add('remove');

        this.isOverlayActive = false;
    }

    /**
     * Update menu slider position offset.
     *
     * @param {number} position - A number from 0 to 2, representing the position to set the menu
     * to, 0 being the root, and 2 the deepest.
     * @param {boolean} disableAnimation - Disable the CSS animation, useful when we want to
     * instantly position the menu.
     */
    slideToMenu(position, disableAnimation) {
        if (disableAnimation) {
            /**
             * Has priority over CSS, because in-lined.
             */
            this.sliderEl.style.transitionDuration = '0s';

            /**
             * Even if the time is set to '0s', an animation is still going on, so we need to wait
             * for it to finish before to restore the transitionDuration.
             */
            this.overlayEl.onanimationend = () => {
                this.sliderEl.style.transitionDuration = null;
            };
        }

        this.sliderEl.style.transform = `translateX(-${(100 / 3) * position}%)`;
    }

    /**
     * Open and initialize menu.
     */
    openMenu() {
        this.flyoutEl.classList.add('touched');
        this.flyoutEl.classList.add('active');
        this.toggleBtnIconEl.classList.add('active');
        this.slideToMenu(0, true);
        this.isMenuOpen = true;
    }

    /**
     * Close menu.
     */
    closeMenu() {
        this.flyoutEl.classList.remove('active');
        this.toggleBtnIconEl.classList.remove('active');
        this.isMenuOpen = false;
    }

    /**
     * Toggle menu.
     */
    toggleMenu() {
        if (this.isMenuOpen) {
            this.hideOverlay();
            this.closeMenu();
        } else {
            const overlayEl = this.showOverlay();
            this.openMenu();
            const boundCloseMenu = this.closeMenu.bind(this);
            overlayEl.addEventListener(
                'click',
                boundCloseMenu,
            );
        }
    }
}
