import * as Sqrl from 'squirrelly';
import { isIe11 } from '../support/detectBrowser';

/**
 * The superNaviData is needed for multiple menus (mobile/desktop). Generating it requires some
 * resources. So once it has been generated the first time, we cache the result here so it can be
 * reused in each instance of SuperNaviManager.
 */
let superNaviDataCache = null;

/**
 * The superNaviDataProvider is responsible for extracting from the DOM the whole menu dataset
 * exposed by Django.
 */
const superNaviDataProvider = () => {
    const menuLvl1Els = [...document.querySelectorAll('[data-super-navi-lvl1-id]')];
    const menuLvl2Els = [...document.querySelectorAll('[data-super-navi-lvl2-id]')];
    const menuLvl3Els = [...document.querySelectorAll('[data-super-navi-lvl3-id]')];
    const highlightEls = [...document.querySelectorAll('[data-super-navi-highlight]')];
    const leadFormPageUrlEl = document.querySelector('[data-super-navi-lead-form-page]');
    const telephoneEl = document.querySelector('[data-super-navi-telephone]');
    const telephoneAvailabilityEl = document.querySelector('[data-super-navi-telephone-availability]');
    const zipcodeSearchUrlEl = document.querySelector('[data-super-navi-zipcode-search]');

    const data = menuLvl1Els.map((menuLvl1El, lvl1Index) => {
        const menuDataLvl1 = {
            data: [],
            highlights: {},
            type: 'lvl1',
            label: menuLvl1El.textContent.trim(),
        };

        const href = menuLvl1El.getAttribute('href');
        if (href) {
            menuDataLvl1.type = 'link';
            menuDataLvl1.href = href;
            return menuDataLvl1;
        }

        /**
         * Get LVL1's highlights' data.
         */
        const highlightsTitleEl = document.querySelector(
            `[data-super-navi-highlights-title-for-id="${lvl1Index.toString()}"]`,
        );

        menuDataLvl1.highlights.title = highlightsTitleEl.dataset.superNaviHighlightsTitle;
        menuDataLvl1.highlights.pages = highlightEls
            .filter((highlightEl) => highlightEl.dataset.superNaviHighlightForId
                === lvl1Index.toString())
            .map((highlightEl) => {
                const highlight = {};

                highlight.label = highlightEl.textContent.trim();
                highlight.href = highlightEl.href;
                highlight.teaser = highlightEl.dataset.superNaviHighlightTeaser;

                return highlight;
            });

        /**
         * Get LVL1's menu data.
         */
        menuDataLvl1.data = menuLvl2Els
            .filter((menuLvl2El) => menuLvl2El.dataset.superNaviLvl2Id.slice(0, 1)
                === lvl1Index.toString())
            .map((menuLvl2El, lvl2Index) => {
                const menuDataLvl2 = {};

                menuDataLvl2.lvl1Index = lvl1Index;
                menuDataLvl2.lvl2Index = lvl2Index;
                menuDataLvl2.label = menuLvl2El.textContent.trim();
                menuDataLvl2.data = menuLvl3Els
                    .filter((menuLvl3El) => menuLvl3El.dataset.superNaviLvl3Id.slice(0, 3)
                        === `${lvl1Index}-${lvl2Index}`)
                    .map((menuLvl3El) => {
                        const menuDataLvl3 = {};

                        menuDataLvl3.label = menuLvl3El.textContent.trim();
                        menuDataLvl3.href = menuLvl3El.href;

                        return menuDataLvl3;
                    });

                return menuDataLvl2;
            });

        return menuDataLvl1;
    });

    return {
        data,
        leadFormPageUrl: leadFormPageUrlEl.href,
        telephone: telephoneEl.textContent.trim(),
        telephoneAvailability: telephoneAvailabilityEl.textContent.trim(),
        zipcodeSearchUrl: zipcodeSearchUrlEl.href,
    };
};

/**
 * SuperNaviManager
 *
 * Never use directly. It regroups common code used by different menus. Typically, you will want to
 * extend it two times:
 * - For a desktop menu
 * - For a mobile menu
 *
 * @param {string} device - Mode "mobile" or "desktop", used to define the overlay class.
 */
class SuperNaviManager {
    /**
     * Update menu slider position offset.
     *
     * @param {string} device - Mode "mobile" or "desktop", used to define the overlay class.
     */
    constructor(device) {
        this.device = device;

        /**
         * Make superNaviData available.
         */
        this.superNaviData = superNaviDataCache || superNaviDataProvider();
        superNaviDataCache = this.superNaviData;

        /**
         * Overlay instance storage, used across showOverlay() and hideOverlay() functions.
         */
        this.overlayEl = null;

        /**
         * Over state, used to avoid having multiple ones.
         */
        this.isOverlayActive = false;

        /**
         * Collecting listeners in order to remove them when re-rendering the menu.
         */
        this.listeners = [];
    }

    /**
     * Since we get the target path information from the "[data-super-navi-path]" tag attribute
     * under the form "0-1-1" (string), we want to convert it into something easily usable like
     * [0, 1, 1] (array).
     *
     * @param {string} path - The path to convert.
     */
    parsePath(path) {
        return path
            .split('-')
            .map((str) => parseInt(str, 10));
    }

    /**
     * Show overlay (the darker background over the whole page when the menu is open).
     */
    showOverlay() {
        if (this.isOverlayActive) {
            return this.overlayEl;
        }

        this.overlayEl = document.createElement('div');
        this.overlayEl.classList.add('overlay', `overlay--${this.device}`);

        const boundhideOverlay = this.hideOverlay.bind(this);
        this.overlayEl.addEventListener(
            'click',
            boundhideOverlay,
        );

        document.getElementsByTagName('body')[0].appendChild(this.overlayEl);

        this.isOverlayActive = true;

        return this.overlayEl;
    }

    /**
     * Hide overlay.
     */
    hideOverlay() {
        /**
         * Internet Explorer 11 doesn't support "onanimationend".
         */
        if (isIe11()) {
            // Equivalent to this.overlayEl.remove(), compatible with IE11.
            this.overlayEl.outerHTML = '';
            return;
        }

        this.overlayEl.onanimationend = () => {
            this.overlayEl.remove();
        };

        this.overlayEl.classList.add('remove');

        this.isOverlayActive = false;
    }

    /**
     * cleanMenuItemsEventListeners() and buildMenuItemsEventListeners() are responsible for
     * handling all menu items event listeners.
     *
     * Since every time a menu template is rendered new elements are added, we need to add a "click"
     * event listener to each of the newly added items.
     *
     * Each time renderMenus() is called, it's important to remove actual event listeners (to avoid
     * piling them up), and add new event listeners to all items.
     */
    cleanMenuItemsEventListeners() {
        Array.prototype.forEach.call(
            this.listeners,
            (listener) => {
                listener.el.removeEventListener(
                    'click',
                    listener.bound,
                );
            },
        );
        this.listeners = [];
    }

    buildMenuItemsEventListeners() {
        Array.prototype.forEach.call(
            document.querySelectorAll('[data-super-navi-path]'),
            (el) => {
                const boundHandleClickMenuItem = this.proceedPath.bind(
                    this,
                    this.parsePath(el.dataset.superNaviPath),
                );
                this.listeners.push({
                    el,
                    bound: boundHandleClickMenuItem,
                });
                el.addEventListener(
                    'click',
                    boundHandleClickMenuItem,
                );
            },
        );
    }

    /**
     * Responsible for rendering one or more menus at once, and refresh event listeners.
     * An "instructions" array example:
     *
     * [
     *     [someDomElement, someTemplate, someDataForTheTemplate],
     *     [anotherDomElement, anotherTemplate, anotherDataForTheTemplate],
     * ]
     *
     * For more information, check out the Squirelly documentation: https://squirrelly.js.org/docs/
     *
     * @param {Array} instructions - An array of array(s). Can contain multiple instructions.
     */
    renderMenus(instructions) {
        this.cleanMenuItemsEventListeners();

        Array.prototype.forEach.call(
            instructions,
            (instruction) => {
                // eslint-disable-next-line no-param-reassign
                instruction[0].innerHTML = Sqrl.render(
                    instruction[1],
                    instruction[2],
                );
            },
        );

        this.buildMenuItemsEventListeners();
    }
}

export {
    SuperNaviManager,
    superNaviDataProvider, // For testing only. Please use superNaviData instead.
};
