const simpleText = '{{ it }}';

const templateDesktopLvl1 = `
    <div class="lvl1">
        {{ @each(it.data) => lvl1, index }}
            {{ @if(lvl1.type === 'link') }}
                <a class="lvl1__label lvl1__label--is-link" href="{{ lvl1.href }}">
                    {{ lvl1.label }}
                </a>
            {{ #else }}
                <div class="lvl1__label lvl1__label--has-children {{ @if(it.activeIndex === index) }}active{{ /if }}"
                     data-super-navi-path="{{ index }}">
                    {{ lvl1.label }}
                </div>
            {{ /if }}
        {{ /each }}
    </div>
`;

const templateDesktopLvl2 = `
    {{ @each(it.data) => lvl2, index }}
        <div class="lvl2__label {{ @if(it.activeIndex === index) }}active{{ /if }}"
             data-super-navi-path="{{ lvl2.lvl1Index }}-{{ index }}">
            {{ lvl2.label }}
        </div>
    {{ /each }}
`;

const templateDesktopLvl3 = `
    <div class="animation-container">
        {{ @each(it) => lvl3, index }}
            <a href="{{ lvl3.href }}"
               class="lvl3__label">
                {{ lvl3.label }}
            </a>
        {{ /each }}
    </div>
`;

const templateHighlights = `
    {{ @each(it) => highlight }}
        <a href="{{ highlight.href }}"
           class="highlights__item">
            <img src="{{ highlight.teaser }}"
                 alt="{{ highlight.label }}"
                 class="highlights__item__image">
            <div class="highlights__item__title">
                {{ highlight.label }}
            </div>
        </a>
    {{ /each }}
`;

const templateMobileLvl1 = `
    <div class="flyout__container">
        {{ @each(it.data) => lvl1, index }}
            {{ @if(lvl1.type === 'link') }}
                <a class="link" href="{{ lvl1.href }}">
                    {{ lvl1.label }}
                </a>
            {{ #else }}
                <div class="link" data-super-navi-path="0-{{ index }}">
                    {{ lvl1.label }}
                    <div class="arrow"></div>
                </div>
            {{ /if }}
        {{ /each }}
    </div>
    <div class="flyout__container">
        <a href="{{ it.zipcodeSearchUrl }}"
           class="link">
            <i class="t-website-map-search"></i>
            Standortsuche
        </a>
    </div>
    <div class="flyout__container text-center">
        <div class="cta-padding">
            <a href="{{ it.leadFormPageUrl }}"
               class="t-btn-1 t-btn-block gtm-global-mobile-menu-toOffer">
                Zum Angebot
            </a>
        </div>
    </div>
`;

const templateMobileLvl2 = `
    <div class="flyout__container">
        <div class="back"
             data-super-navi-path="0">
             Zurück zu Menü
        </div>
    </div>
    <div class="title">
        {{ it.label }}
    </div>
    <div class="flyout__container">
        {{ @each(it.data) => lvl2, index }}
            <div class="link"
                 data-super-navi-path="0-{{ lvl2.lvl1Index }}-{{ index }}">
                {{ lvl2.label }}
                <div class="arrow"></div>
            </div>
        {{ /each }}
    </div>
    <div class="separator"></div>
    <div class="flyout__container highlights">
        <div class="highlights__title">
            {{ it.highlights.title }}
        </div>
        {{ @each(it.highlights.pages) => page }}
            <a href="{{ page.href }}"
               class="highlights__item">
                <img src="{{ page.teaser }}"
                     alt="{{ page.label }}"
                     class="highlights__item__image">
                <div class="highlights__item__title">
                    {{ page.label }}
                </div>
            </a>
        {{ /each }}
    </div>
`;

const templateMobileLvl3 = `
    <div class="flyout__container">
        <div class="back"
             data-super-navi-path="0-{{ it.lvl1Index }}">
             Zurück zu {{ it.parentLabel }}
        </div>
    </div>
    <div class="title">
        {{ it.label }}
    </div>
    <div class="flyout__container">
        {{ @each(it.data) => lvl3, index }}
            <a class="link"
               href="{{ lvl3.href }}">
                {{ lvl3.label }}
            </a>
        {{ /each }}
    </div>
`;

export {
    simpleText,
    templateDesktopLvl1,
    templateDesktopLvl2,
    templateDesktopLvl3,
    templateHighlights,
    templateMobileLvl1,
    templateMobileLvl2,
    templateMobileLvl3,
};
